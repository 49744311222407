import { apiService } from "../services";
import { authHeader } from "../helpers";

export const mapasService = {
    recuperaBase64Mapa,
    recuperaCoordenadasPrincipal,
    recuperaCoordenadasEds,
    recuperaCoordenadasEfs,
    recuperaCoordenadasEap,
    recuperaCoordenadasDivisas,
    recuperaCoordenadasClassificacao,
    recuperaCoordenadasEmpresas,
    recuperaCoordenadasEmpresa,
    recuperaCoordenadasInundacoes
};

function recuperaBase64Mapa(usuario, guid) {
    const requestOptions = {
        method: "GET",
    };

    var url =
        apiService.enderecoApi() +
        "/historico/mapa/agregador/v1" +
        "?usuario=" +
        usuario +
        "&codigotransacao=" +
        guid;

    return fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
            return resultado;
        });
}

function recuperaCoordenadasPrincipal(guid) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    // var url = apiService.enderecoApi() +
    var url =
        apiService.enderecoApi() +
        "/historico/coordenadas/central" +
        "?cod_agrupador=" +
        guid;

    return fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
            return resultado;
        });
}

function recuperaCoordenadasEds(guid) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    var url =
        apiService.enderecoApi() +
        "/historico/poligonos/eds" +
        "?cod_agrupador=" +
        guid;

    return fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
            return resultado;
        });
}

function recuperaCoordenadasEfs(guid) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    var url =
        apiService.enderecoApi() +
        "/historico/coordenadas/efs" +
        "?cod_agrupador=" +
        guid;

    return fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
            return resultado;
        });
}

function recuperaCoordenadasEap(guid) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    var url =
        apiService.enderecoApi() +
        "/historico/coordenadas/eap" +
        "?cod_agrupador=" +
        guid;

    return fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
            return resultado;
        });
}

function recuperaCoordenadasDivisas(guid) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    var url =
        apiService.enderecoApi() +
        "/historico/poligonos/divisas" +
        "?cod_agrupador=" +
        guid;

    return fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
            return resultado;
        });
}

function recuperaCoordenadasClassificacao(guid) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    var url =
        apiService.enderecoApi() +
        "/historico/poligonos/classificacao" +
        "?cod_agrupador=" +
        guid;

    return fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
            return resultado;
        });
}

function recuperaCoordenadasEmpresas(guid) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    var url =
        apiService.enderecoApi() +
        "/historico/coordenadas/sociedades/empresas" +
        "?cod_agrupador=" +
        guid;

    return fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
            return resultado;
        });
}
function recuperaCoordenadasEmpresa(guid) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    var url =
        apiService.enderecoApi() +
        "/historico/coordenadas/empresa" +
        "?cod_agrupador=" +
        guid;

    return fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
            return resultado;
        });
}

function recuperaCoordenadasInundacoes(guid) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    var url =
        apiService.enderecoApi() +
        "/historico/poligonos/desastres/inundacoes" +
        "?cod_agrupador=" +
        guid;

    return fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
            return resultado;
        });
}
