import { query } from "vue-gtag";
import { authHeader } from "../helpers";
import { authHeaderPost } from "../helpers";
import { apiService } from ".";
import axios from "axios";

export const gestorArquivosService = {
  enviaUploadSimples,
  // enviaUploadAppend,
  enviaUploadBlock,
  enviaUploadBlockCommit,

  removeArquivo,
  destroiArquivo,
  restauraArquivo,
  alteraArquivo,

  downloadArquivoSimples,
  downloadArquivoStreaming,
  cancelarRequisicaoSimples,
};

const controllers = {};

function cancelarRequisicaoSimples(id) {
  if (controllers[id]) {
    controllers[id].abort();
    delete controllers[id];
  }
}

async function downloadArquivoStreaming(
  queryString,
  camposRequest,
  callbackProgresso
) {
  const id = camposRequest.NomeArquivo;
  if (controllers[id]) {
    controllers[id].abort();
  }

  const controller = new AbortController();
  controllers[id] = controller;
  const { signal } = controller;

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    signal,
  };

  var url =
    apiService.enderecoApiGestorBases() +
    "/gestor_bases/projeto/arquivo/download/streaming?" +
    queryString;

  try {
    const response = await fetch(url, requestOptions);
    const contentDisposition = response.headers.get("content-disposition");
    const fileName = contentDisposition?.match(/filename="?(.+)"?/)[1] || id;
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const contentLength =
      camposRequest.TamanhoArquivo || response.headers.get("content-length");
    if (!contentLength) {
      throw new Error(
        "O tamanho do arquivo não está disponível para calcular o progresso."
      );
    }

    const total = parseInt(contentLength, 10);
    let loaded = 0;

    const reader = response.body.getReader();
    const stream = new ReadableStream({
      async start(controller) {
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          loaded += value.length;
          const percentCompleted = Math.round((loaded * 100) / total);
          callbackProgresso(percentCompleted);
          controller.enqueue(value);
        }
        controller.close();
      },
    });

    const downloadUrl = URL.createObjectURL(await new Response(stream).blob());

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    if (error.name !== "AbortError") {
      throw error;
    }
  } finally {
    delete controllers[id];
  }
}

async function downloadArquivoSimples(
  queryString,
  camposRequest,
  callbackProgresso
) {
  const id = camposRequest.NomeArquivo;
  if (controllers[id]) {
    controllers[id].abort();
  }

  const controller = new AbortController();
  controllers[id] = controller;
  const { signal } = controller;

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    signal,
  };

  var url =
    apiService.enderecoApiGestorBases() +
    "/gestor_bases/projeto/arquivo/download?" +
    queryString;

  try {
    const response = await fetch(url, requestOptions);
    const contentDisposition = response.headers.get("content-disposition");
    const fileName = contentDisposition?.match(/filename="?(.+)"?/)[1] || id;
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const contentLength =
      camposRequest.TamanhoArquivo || response.headers.get("content-length");
    if (!contentLength) {
      throw new Error(
        "O tamanho do arquivo não está disponível para calcular o progresso."
      );
    }

    const total = parseInt(contentLength, 10);
    let loaded = 0;

    const reader = response.body.getReader();
    const stream = new ReadableStream({
      async start(controller) {
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          loaded += value.length;
          const percentCompleted = Math.round((loaded * 100) / total);
          callbackProgresso(percentCompleted);
          controller.enqueue(value);
        }
        controller.close();
      },
    });

    const downloadUrl = URL.createObjectURL(await new Response(stream).blob());

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    if (error.name !== "AbortError") {
      throw error;
    }
  } finally {
    delete controllers[id];
  }
}

async function restauraArquivo(camposRequest) {
  var formData = new FormData();

  formData.append("IdentificadorProjeto", camposRequest.IdentificadorProjeto);
  formData.append("NomeArquivo", camposRequest.NomeArquivo);

  let url =
    apiService.enderecoApiGestorBases() +
    "/gestor_bases/projeto/arquivo/restaura";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}

async function alteraArquivo(camposRequest) {
  var formData = new FormData();

  formData.append("IdentificadorProjeto", camposRequest.IdentificadorProjeto);
  formData.append("IdentificadorArquivo", camposRequest.IdentificadorArquivo);
  formData.append("NomeArquivo", camposRequest.NomeArquivo);
  formData.append("TipoArquivo", camposRequest.TipoArquivo);
  formData.append("DataExpiracaoArquivo", camposRequest.DataExpiracaoArquivo);

  let url =
    apiService.enderecoApiGestorBases() +
    "/gestor_bases/projeto/arquivo/altera";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}
async function destroiArquivo(camposRequest) {
  var formData = new FormData();

  formData.append("IdentificadorProjeto", camposRequest.IdentificadorProjeto);
  formData.append("IdentificadorArquivo", camposRequest.IdentificadorArquivo);

  let url =
    apiService.enderecoApiGestorBases() +
    "/gestor_bases/projeto/arquivo/destroi";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}

async function removeArquivo(camposRequest) {
  var formData = new FormData();

  formData.append("IdentificadorProjeto", camposRequest.IdentificadorProjeto);
  formData.append("IdentificadorArquivo", camposRequest.IdentificadorArquivo);

  let url =
    apiService.enderecoApiGestorBases() +
    "/gestor_bases/projeto/arquivo/deleta";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}

// function enviaUploadBlockCommit(camposRequest) {
//   var postData = camposRequest;
//   const requestOptions = {
//     method: "POST",
//     headers: authHeader(),
//     body: JSON.stringify(postData),
//   };

//   return fetch(
//     apiService.enderecoApiGestorBases() +
//       "/gestor_bases/projeto/arquivo/upload/chunk/commit",
//     requestOptions
//   )
//     .then(apiService.handleResponse)
//     .then((resultado) => {
//       return resultado;
//     });
// }

async function enviaUploadBlockCommit(camposRequest) {
  var formData = new FormData();

  formData.append("IdentificadorProjeto", camposRequest.IdentificadorProjeto);
  formData.append("IdentificadorArquivo", camposRequest.IdentificadorArquivo);
  formData.append("NomeArquivo", camposRequest.NomeArquivo);
  formData.append("Dataset", "");
  formData.append("Caminho", camposRequest.Caminho);
  formData.append("TamanhoBlocos", "");
  formData.append("BlobIds", camposRequest.BlobIds);
  formData.append("Validade", camposRequest.Validade);

  let url =
    apiService.enderecoApiGestorBases() +
    "/gestor_bases/projeto/arquivo/upload/chunk/commit";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}

async function enviaUploadBlock(camposRequest) {
  var formData = new FormData();

  formData.append("IdentificadorProjeto", camposRequest.IdentificadorProjeto);
  formData.append("NomeArquivo", camposRequest.NomeArquivo);
  formData.append("Caminho", camposRequest.Caminho);
  formData.append("Dataset", "");
  formData.append("TipoArquivo", "Padrao");
  formData.append("IdChunk", camposRequest.IdChunk);
  formData.append("Blob", camposRequest.Blob);

  let url =
    apiService.enderecoApiGestorBases() +
    "/gestor_bases/projeto/arquivo/upload/chunk/block";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}

// async function enviaUploadAppend(camposRequest) {
//   var formData = new FormData();

//   formData.append("IdentificadorProjeto", camposRequest.IdentificadorProjeto);
//   formData.append("NomeArquivo", camposRequest.NomeArquivo);
//   formData.append("IdChunk", camposRequest.IdChunk);
//   formData.append("Blob", camposRequest.Chunk);

//   let url =
//     apiService.enderecoApiGestorBases() +
//     "/gestor_bases/projeto/arquivo/upload/chunk";

//   let header = authHeaderPost(formData);

//   return axios
//     .post(url, formData, { headers: header })
//     .catch((resultado) => {
//       const error = apiService.handleErrorAxios(resultado);
//       throw new Error(error.response.data.Message);
//     })
//     .then((resultado) => {
//       return resultado;
//     });
// }

async function enviaUploadSimples(camposRequest) {
  var formData = new FormData();

  formData.append("IdentificadorProjeto", camposRequest.IdentificadorProjeto);
  formData.append("Arquivo", camposRequest.Arquivo);

  let url =
    apiService.enderecoApiGestorBases() +
    "/gestor_bases/projeto/arquivo/upload";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}
