import { query } from 'vue-gtag';
import { authHeader } from '../helpers';
import { apiService } from '.';

export const lotesService = {
  recuperaLayoutLotes,
  recuperaLayoutAtributo,
  recuperaLayoutModelo,
};

function recuperaLayoutLotes(queryString) {

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var url = apiService.enderecoApiLotes() + "/lotes/layout?" + queryString;
  
  return fetch(url, requestOptions).then(apiService.handleResponse).then(resultado => {
    return resultado;
  });
}

//atributo
function recuperaLayoutAtributo(queryString) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader(),
  };
  let url = apiService.enderecoApi() + "/v3/box/layout?" + queryString;
  // let url = "https://api3.outbox.com.br/v3/box/layout?" + queryString;
  return fetch(url, requestOptions)
  .then(apiService.handleResponse)
  .then(resultado => {
      return resultado;
  })
}
//modelo
function recuperaLayoutModelo(queryString) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader(),
  };
  let url = apiService.enderecoApi() + "/v3/modelo/layout?" + queryString;
  return fetch(url, requestOptions)
  .then(apiService.handleResponse)
  .then(resultado => {
      return resultado;
  })
}
