import { defineStore } from "pinia";
import { apiService } from "../services";
import { authHeader } from "../helpers";
import { chatService } from "@/services";

export const chatOutboxStore = defineStore("chatOutbox", {
  state: () => ({
    chatAberto: false,
    abrirChat: false,
    entradaChat: "",
    historicoChat: [],
    contexto: {
      codigo_agrupador: "",
      rota: "/inicio",
      nome: "",
      descricao: "",
    },

    thread_id: "",
    resultadoRequisição: {},
    resultadoRequisiçãoConsorcio: {},
    resultadoRequisiçãoAtribuiGrupo: {},

    cpfEhFavorito: false,
  }),

  actions: {
    resetStoreChat() {
      this.chatAberto = false;
      this.abrirChat = false;
      this.entradaChat = "";
      this.thread_id = "";
      this.historicoChat = [];
    },

    recebeContexto(contexto) {
      contexto.descricao = this.obterDescricaoPorRota(contexto.rota);

      this.contexto = contexto;
    },

    abrirJanelaChat() {
      this.chatAberto = true;
    },

    fecharJanelaChat() {
      this.chatAberto = false;
    },

    obterDescricaoPorRota(rota) {
      const descricaoDasRotas = {
        "/inicio": "Página inicial",
        "/consultas/box/": "Página de consultas de Box",
        "/consultas/superbox/": "Página de consultas Superbox",
        "/consultas/modelos/": "Página de consultas de Modelos",
        "/consultas/historico": "Página de históricos de consultas",
        "/consultas/historico": "Página de histórico de consulta",
        "/backtest/nova/": "Página de execução de Backtest",
        "/backtest/historico_backtest": "Página de histórico de Backtest",
        "/gestor_bases/projetos": "Página de projetos do gestor de bases",
        "/gestor_bases/area_transferencia": "Página de área de transferência",
        "/gestor_bases/historico": "Página de histórico do gestor de bases",
        "/suporte/referencia": "Página de documentação de referência",
        "/doc/books": "Página de documentação de books",
        "/doc/box_modelos": "Página de documentação de box de modelos",
        "/doc/box_atributos": "Página de documentação de box de atributos",
        "/suporte/contato": "Página de contato",
        "/logs": "Página de atualizações",
        "/integracoes_skynet": "Página de integração com Skynet",
        "/preferencias": "Párgina de preferências",
        "/conta": "Página de dados do usuário",
      };

      for (const [padrao, descricao] of Object.entries(descricaoDasRotas)) {
        if (rota.includes(padrao)) {
          return descricao;
        }
      }
      return "Descrição não disponível";
    },

    recebeChatPersonalizado(tipo) {
      var pergunta = "";
      if (tipo == "MapaRiscos") {
        pergunta =
          "Fale sobre o mapa geral de riscos e faça um resumo do cliente.";
      }
      this.entradaChat = pergunta;
      this.abrirChat = true;
    },

    async requisicaoChat(cod_agr, texto, thread_id) {
      var objetoConsulta = {};
      objetoConsulta["cod_agrupador"] = cod_agr;
      objetoConsulta["filtro_boxes"] = "BOXSUPPF005";
      objetoConsulta["query_text"] = texto;
      objetoConsulta["thread_id"] = thread_id;

      await chatService
        .realizaRequisicaoChat(objetoConsulta)
        .catch((error) => {
          return error;
        })
        .then((resultado) => {
          this.resultadoRequisição = resultado;
        });
    },

    async requisicaoChatConsorcio(texto) {
      var objetoConsulta = {};
      objetoConsulta["user_prompt"] = texto;

      await chatService
        .realizaRequisicaoChatConsorcio(objetoConsulta)
        .catch((error) => {
          return error;
        })
        .then((resultado) => {
          this.resultadoRequisiçãoConsorcio = resultado;
        });
    },
    
    async requisicaoAtribuiGrupo(objetoConsulta) {

      await chatService
        .recuperaGrupoConsorcio(objetoConsulta)
        .catch((error) => {
          return error;
        })
        .then((resultado) => {
          this.resultadoRequisiçãoAtribuiGrupo = resultado;
        });
    },
  },
});
