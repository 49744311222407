import { defineStore } from "pinia";
import { apiService } from "../services";
import { consultasService } from "@/services";

export const dadosConsultaAddStore = defineStore("dadosConsultaAdd", {
  state: () => ({
    abreSelecaoConsultaCiclica: false,
    documentoConsultar: "",
    tipoDocumentoConsultar: "",
    dataDaConsulta: "",

    ativaTrilhaConsultas: false,
    consultasTrilha: [],
    consultaSelecionada: "",
    tipoConsultaSelecionada: "",
    codRefSelecionado: "",
    trilhaSelecionada: {},
    realizarNovaConsulta: false,

    ativaNovaConsultaMod: false,
    ativaTelaResultadosModelos: false,
    ativaResultadosModelos: false,
    ativaTelaSuperbox: false,
    ativaResultadosSuperbox: false,
  }),

  actions: {
    atualizaNovaConsultaMod() {
      this.ativaNovaConsultaMod = !this.ativaNovaConsultaMod;
    },
    atualizaTelaResultadosModelos() {
      this.ativaTelaResultadosModelos = !this.ativaTelaResultadosModelos;
    },

    abrirSelecaoConsulta(documento, tipoConsulta) {
      this.abreSelecaoConsultaCiclica = true;
      this.documentoConsultar = documento;
      this.tipoDocumentoConsultar = tipoConsulta;
    },
    fecharSelecaoConsulta() {
      this.abreSelecaoConsultaCiclica = false;
    },

    resetConsutaAdd() {
      this.abreSelecaoConsultaCiclica = false;
      this.documentoConsultar = "";
      this.tipoDocumentoConsultar = "";
      this.dataDaConsulta = "";

      this.ativaTrilhaConsultas = false;
      this.consultasTrilha = [];
      this.consultaSelecionada = "";
      this.tipoConsultaSelecionada = "";
      this.codRefSelecionado = "";
      this.trilhaSelecionada = {};
      this.realizarNovaConsulta = false;

      this.ativaNovaConsultaMod = false;
      this.ativaTelaResultadosModelos = false;
      this.ativaResultadosModelos = false;
      this.ativaTelaSuperbox = false;
      this.ativaResultadosSuperbox = false;
    },
  },
});
