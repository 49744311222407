import { authHeader } from "../helpers";
import { apiService } from ".";
import { authHeaderPost } from "../helpers";
import axios from "axios";

export const chatService = {
  realizaRequisicaoChat,
  realizaRequisicaoChatConsorcio,
  recuperaGrupoConsorcio,
  recuperaTabelaGruposConsorcio,
  restauraTabelaGruposConsorcio
};

function realizaRequisicaoChat(camposRequest) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(camposRequest),
  };

  let url = apiService.enderecoApiChatOutbox() + "/chat";
  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .catch((error) => {
      throw error;
    })
    .then((resultado) => {
      return resultado;
    });
}

function realizaRequisicaoChatConsorcio(camposRequest) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(camposRequest),
  };

  let url = apiService.enderecoApiChatOutbox() + "/chat/consorcio/query";
  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .catch((error) => {
      throw error;
    })
    .then((resultado) => {
      return resultado;
    });
}
function recuperaGrupoConsorcio(camposRequest) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(camposRequest),
  };

  let url = apiService.enderecoApiChatOutbox() + "/chat/consorcio/atribui_grupo";
  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .catch((error) => {
      throw error;
    })
    .then((resultado) => {
      return resultado;
    });
}

function recuperaTabelaGruposConsorcio(guid) {
  const requestOptions = {
      method: "GET",
      headers: authHeader(),
  };

  var url =
      apiService.enderecoApiChatOutbox() +
      "/chat/consorcio/tabela_sumario"

  return fetch(url, requestOptions)
      .then(apiService.handleResponse)
      .then((resultado) => {
          return resultado;
      });
}

function restauraTabelaGruposConsorcio(guid) {
  const requestOptions = {
      method: "GET",
      headers: authHeader(),
  };

  var url =
      apiService.enderecoApiChatOutbox() +
      "/chat/consorcio/restaura_dados"

  return fetch(url, requestOptions)
      .then(apiService.handleResponse)
      .then((resultado) => {
          return resultado;
      });
}
