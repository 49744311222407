import { authHeader } from '../helpers';
import { apiService } from '.';
import { authHeaderPost } from '../helpers';
import axios from 'axios';

export const skynetService = {
  submeteIntegracaoSkynet,
  recuperaDadosIntegracao,
  recuperaExecucoesSkynet,
};

async function submeteIntegracaoSkynet(camposRequest) {
  var formData = new FormData();
  // var tipoPessoa = camposRequest.tipo_pessoa == "Pessoa Física" ? 'pessoa_fisica' : 'pessoa_juridica'
  // var codRef = camposRequest.tipo_pessoa == "Pessoa Física" ? 'BOXCHPPF001' : 'BOXCHPPJ001'

  formData.append('IdentificadorLote', camposRequest.IdentificadorLote)
  formData.append('Produto', camposRequest.Produto)
  formData.append('TextoParametros', camposRequest.TextoParametros)

  let url = apiService.enderecoApiLotes() + "/lote/integracoes/skynet/cria";

  let header = authHeaderPost(formData);

  return axios.post(url, 
    formData, 
    { headers: header }
    )
  .catch(resultado => {
    const error = apiService.handleErrorAxios(resultado)
    throw new Error(error.response.data.Message)
  })
  .then(resultado => {
    return resultado;
  })
}

function recuperaDadosIntegracao(queryString) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var url = apiService.enderecoApiLotes() + "/lote/integracoes/skynet?" + queryString;
  return fetch(url, requestOptions).then(apiService.handleResponse).then(resultado => {
    return resultado;
  });
}
function recuperaExecucoesSkynet(queryString) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var url = apiService.enderecoApiSkynet() + "/skynet?" + queryString;
  return fetch(url, requestOptions).then(apiService.handleResponse).then(resultado => {
    return resultado;
  });
}
