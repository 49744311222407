import { query } from "vue-gtag";
import { authHeader } from "../helpers";
import { authHeaderPost } from "../helpers";
import { apiService } from ".";
import axios from "axios";

export const gestorProjetosService = {
  criaProjeto,
  recuperaListaProjetos,
  recuperaProjeto,
  
  deletaProjeto,
  destroiProjeto,
  editaProjeto,
  restauraProjeto,

};

async function criaProjeto(nome) {
  var formData = new FormData();
  formData.append("nomeProjeto", nome);

  let url = apiService.enderecoApiGestorBases() + "/gestor_bases/projeto/cria";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}

async function recuperaListaProjetos(queryString) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  var url =
    apiService.enderecoApiGestorBases() +
    "/gestor_bases/projetos?" +
    queryString;
  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}

async function recuperaProjeto(queryString) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  var url =
    apiService.enderecoApiGestorBases() +
    "/gestor_bases/projeto?" +
    queryString;
  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    })
    .catch((error) => {
      throw error;
    });
}

async function deletaProjeto(projeto) {
  var formData = new FormData();
  formData.append("IdentificadorProjeto", projeto.IdentificadorProjeto);

  let url = apiService.enderecoApiGestorBases() + "/gestor_bases/projeto/deleta";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}

async function destroiProjeto(projeto) {
  var formData = new FormData();
  formData.append("IdentificadorProjeto", projeto.IdentificadorProjeto);

  let url = apiService.enderecoApiGestorBases() + "/gestor_bases/projeto/destroi";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}

async function editaProjeto(projeto) {
  var formData = new FormData();
  formData.append("IdentificadorProjeto", projeto.IdentificadorProjeto);
  formData.append("NomeProjeto", projeto.NomeProjeto);
  formData.append("StatusId", projeto.StatusId);

  let url = apiService.enderecoApiGestorBases() + "/gestor_bases/projeto/edita";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}

async function restauraProjeto(projeto) {
  var formData = new FormData();
  formData.append("IdentificadorProjeto", projeto.IdentificadorProjeto);

  let url = apiService.enderecoApiGestorBases() + "/gestor_bases/projeto/restaura";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}
