import { defineStore } from "pinia";
import { apiService } from "../services";
import { authHeader } from "../helpers";

export const booksStore = defineStore('books', {
    state: () => ({
        books: [],
        books_v3: [],
    }),
    actions: {
        getAtributos(bk,target) {
            const book = this.books.filter((book) => book.codigo_book == `${bk}`)
            const consulta = book[0].consultas.filter(consulta => consulta.nome_parametro_consulta == target)
            return consulta[0].atributos
        },

        async getBooksV3() {
            const requestOptions = {
                method: 'GET',
                headers: authHeader(),
            };
            let url = apiService.enderecoApi() + "/v3/book/layout";

            const books_v3 = await fetch(url, requestOptions)
            .then(apiService.handleResponse)
            .then(resultado => {
                return resultado;
            })
            this.books_v3 = books_v3
        },

        getAtributosV3(bk,target) {
            const book_v3 = this.books_v3.filter((book_v3) => book_v3.Codigo == `${bk}`)
            const consulta = book_v3[0].consultas.filter(consulta => consulta.nome_parametro_consulta == target)
            return consulta[0].atributos
        },
    }
})
