import { query } from 'vue-gtag';
import { authHeader } from '../helpers';
import { apiService } from '../services';

export const historicoService = {
  recuperaDadosHistorico,
  contagemPeriodo,
  recuperaConsulta,
  recuperaDadosHistoricoLotes,
  recuperaVariavelAmostra,
  recuperaVariavelAmostraComposta,
  recuperaTaxaEventoPorSafra,
  recuperaTaxaEventoCompostaPorSafra
};

function recuperaDadosHistorico(queryString) {

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var url = apiService.enderecoApi() + "/historico/paginado?" + queryString
  return fetch(url, requestOptions).then(apiService.handleResponse).then(resultado => {
    return resultado;
  });
}

function contagemPeriodo(queryString) {

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var url = apiService.enderecoApi() + "/historico/contagem?" + queryString
  return fetch(url, requestOptions).then(apiService.handleResponse).then(resultado => {
    return resultado;
  });
}

function recuperaConsulta(queryString) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  var url = apiService.enderecoApi() + "/historico/consulta?" + queryString;

  return fetch(url, requestOptions).then(apiService.handleResponse).then(resultado => {
    return resultado
  })
}

function recuperaDadosHistoricoLotes(queryString) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var url = apiService.enderecoApiLotes() + "/lotes?" + queryString;
  return fetch(url, requestOptions).then(apiService.handleResponse).then(resultado => {
    return resultado;
  });
}

function recuperaVariavelAmostra(queryString) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var url = apiService.enderecoApiLotes() + "/lote/amostra?" + queryString;
  return fetch(url, requestOptions).then(apiService.handleResponse).then(resultado => {
    return resultado;
  });
}
function recuperaVariavelAmostraComposta(queryString) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var url = apiService.enderecoApiLotes() + "/lote/amostra_composta?" + queryString;
  return fetch(url, requestOptions).then(apiService.handleResponse).then(resultado => {
    return resultado;
  });
}
function recuperaTaxaEventoPorSafra(queryString) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var url = apiService.enderecoApiLotes() + "/lote/amostra_safra?" + queryString;
  return fetch(url, requestOptions).then(apiService.handleResponse).then(resultado => {
    return resultado;
  });
}
function recuperaTaxaEventoCompostaPorSafra(queryString) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  var url = apiService.enderecoApiLotes() + "/lote/amostra_composta_safra?" + queryString;
  return fetch(url, requestOptions).then(apiService.handleResponse).then(resultado => {
    return resultado;
  });
}