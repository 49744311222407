import * as XLSX from "xlsx/xlsx.mjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export function create(books) {
  const wb = XLSX.utils.book_new();
  const date = new Date();
  const name =
    "Outbox_Documentacao_Books" +
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate();

  wb.Props = {
    Title: name,
    Subject: "Documentação Outbox",
    Author: "Portal Outbox",
    CreatedDate: new Date(),
  };

  wb.SheetNames.push("Espelho de atributos");
  var content = [];
  const HeaderAtributo = ["Atributo", "Book", "Tipo", "Descrição"];

  content.push(HeaderAtributo);
  books.forEach((book) => {
    book.ListaAtributos.forEach((atributo) => {
      content.push([
        atributo.Identificador,
        book.Codigo,
        atributo.Tipo,
        atributo.Descricao,
      ]);
    });
  });

  const ws = XLSX.utils.aoa_to_sheet(content);

  wb.Sheets["Espelho de atributos"] = ws;

  XLSX.writeFile(wb, `${name}.xlsx`, { bookType: "xlsx", type: "bynary" });
}


export function createBox(box, cod_ref) {
  const wb = XLSX.utils.book_new();
  const date = new Date();
  const name =
    "Outbox_Documentacao_Box_" +
    cod_ref +
    "_" +
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate();

  wb.Props = {
    Title: name,
    Subject: "Documentação Outbox",
    Author: "Portal Outbox",
    CreatedDate: new Date(),
  };

  wb.SheetNames.push("Espelho de atributos");
  var content = [];
  const HeaderAtributo = ["Atributo", "Tipo", "Descrição"];

  content.push(HeaderAtributo);
  // box.forEach((book) => {
    box.ListaAtributos.forEach((atributo) => {
      content.push([
        atributo.Identificador,
        atributo.Tipo,
        atributo.Descricao,
      ]);
    });
  // });

  const ws = XLSX.utils.aoa_to_sheet(content);

  wb.Sheets["Espelho de atributos"] = ws;

  XLSX.writeFile(wb, `${name}.xlsx`, { bookType: "xlsx", type: "bynary" });
}

export function createPDF(targetElement, nomeArquivo) {
  html2canvas(targetElement, {
    width: targetElement.offsetWidth,
    height: targetElement.offsetHeight,
    allowTaint: true,
    useCORS: true,
  }).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");

    const imgWidth = 200;
    var imgHeight = (canvas.height * 210) / canvas.width;
    const alturaFolha = imgHeight > 297 ? imgHeight + 20 : 297;

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [210, alturaFolha],
      marginLeft: 5,
      marginRight: 5,
    });

    pdf.addImage(imgData, "PNG", 5, 5, imgWidth, imgHeight);

    pdf.save(nomeArquivo + ".pdf");
  });
}
export function createPDFZip(targetElement, nomeArquivo) {
  html2canvas(targetElement, {
    width: targetElement.offsetWidth,
    height: targetElement.offsetHeight,
    allowTaint: true,
    useCORS: true,
  }).then(async (canvas) => {
    const imgData = canvas.toDataURL("image/png");

    const imgWidth = 200;
    var imgHeight = (canvas.height * 210) / canvas.width;
    const alturaFolha = imgHeight > 297 ? imgHeight + 20 : 297;

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [210, alturaFolha],
      marginLeft: 5,
      marginRight: 5,
    });

    pdf.addImage(imgData, "PNG", 5, 5, imgWidth, imgHeight);

    const pdfBlob = pdf.output("blob");
    const zip = new JSZip();

    zip.file(nomeArquivo + ".pdf", pdfBlob, { binary: true });

    const zipBlob = await zip.generateAsync({
      type: "blob",
      compression: "DEFLATE",
      compressionOptions: {
        level: 5,
      },
    });
    saveAs(zipBlob, nomeArquivo + ".zip");
  });
}
